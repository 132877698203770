// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-animated-logo-js": () => import("./../src/pages/animated-logo.js" /* webpackChunkName: "component---src-pages-animated-logo-js" */),
  "component---src-pages-demo-js": () => import("./../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-feedback-js": () => import("./../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-2-js": () => import("./../src/pages/logo-2.js" /* webpackChunkName: "component---src-pages-logo-2-js" */),
  "component---src-pages-logo-3-js": () => import("./../src/pages/logo-3.js" /* webpackChunkName: "component---src-pages-logo-3-js" */),
  "component---src-pages-logo-4-js": () => import("./../src/pages/logo-4.js" /* webpackChunkName: "component---src-pages-logo-4-js" */),
  "component---src-pages-logo-js": () => import("./../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

